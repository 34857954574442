import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";

export default function SignInPage() {
    return (
        <Layout>
            <Seo title="Sign Out" />
            <div className="flex flex-col md:justify-center items-center p-4 w-full h-screen -mt-16 pt-16">
                <div className="absolute inset-0 mesh-bg -z-10"></div>
                <SignoutBox />
            </div>
        </Layout>
    );
}

// local components

const SignoutBox = () => {
    return (
        <div className="flex flex-col rounded w-full md:w-1/2 lg:w-1/3 bg-lightgreen1 px-4 py-6">
            <h1 className="text-lg mb-2">Signed out</h1>
            <p>Good day, Bye</p>
            <Link to="/signin" className="primary btn my-4">
                Go to Sign in
            </Link>
            <div className="flex flex-row justify-center items-center text-base">
                <span className="px-2">Don't have an account?</span>
                <Link to="/signup" className="text-black font-bold underline">
                    Sign up
                </Link>
            </div>
        </div>
    );
};
